<template>
  <main class="home">
    <section class="visual_wrapper" ref="refVisual">
      <div class="main_visual">
        <div class="text_box">
          <p>
            여행부터 축제까지<br />
            비대면 스탬프투어는
          </p>
          <h1>스탬프 팝</h1>
          <span>
            <p>페스티벌 요금제 출시!</p>
            <p>
              이제, 기간이 짧은 축제/행사도<br />
              스탬프 팝하세요
            </p>
          </span>
        </div>
        <div class="img_box">
          <img
            class="mobile_img_01"
            src="@/assets/img/stuff.png"
            alt="모바일 화면"
          />
        </div>
      </div>
      <div class="scroll_down">
        <img src="@/assets/img/scroll-icon.png" alt="scroll down" />
        <p>SCROLL DOWN</p>
      </div>
    </section>
    <section class="entry_wrapper" ref="refEntry">
      <span>
        <p>페스티벌 요금제 출시!</p>
        <p>
          이제, 기간이 짧은 축제/행사도<br />
          스탬프 팝하세요
        </p>
      </span>
      <div class="entry">
        <div class="title_box">
          <h1>어떻게 하면 우리 관광지(축제)를 <br />성공시킬까?</h1>
          <h1>
            스탬프 팝으로 알리고, 줄이고, <br />더 즐기게 하세요<span>.</span>
          </h1>
        </div>
        <div class="entry_contents" v-if="window.width <= 750">
          <carousel
            :items="1"
            :loop="true"
            :autoplay="true"
            :autoplayTimeout="6000"
            :autoplayHoverPause="true"
            :mouseDrag="true"
            :touchDrag="true"
            :navText="['']"
          >
            <div
              class="entry_content"
              v-for="(item, index) in entrySlide"
              :key="index"
            >
              <img :src="require(`@/assets/img/${item.src}`)" :alt="item.alt" />
              <h3>{{ item.h3 }}<span>.</span></h3>
              <p v-html="item.p"></p>
            </div>
          </carousel>
        </div>
        <div class="entry_contents" v-else>
          <div class="entry_tab_box">
            <template>
              <div
                class="entry_content"
                v-for="(item, index) in entrySlide"
                :key="index"
              >
                <img
                  :src="require(`@/assets/img/${item.src}`)"
                  :alt="item.alt"
                />
                <h3>{{ item.h3 }}<span>.</span></h3>
                <p v-html="item.p"></p>
              </div>
            </template>
          </div>
        </div>
      </div>
    </section>
    <section class="partners_wrapper">
      <div class="partners">
        <div class="title_box">
          <h1>이미 많은 지역자치단체에서</h1>
          <h1>
            스탬프 팝을 통해 비대면 여행을 제공하고 있습니다<span>.</span>
          </h1>
        </div>
        <div class="video_box">
          <div class="video_container">
            <iframe
              width="100%"
              height="100%"
              src="https://www.youtube.com/embed/hV5wVmEnedY"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            ></iframe>
          </div>
        </div>
        <div class="img_box">
          <img
            v-for="(item, index) in partners"
            :key="index"
            :src="require(`@/assets/img/${item.src}`)"
            :alt="item.alt"
          />
        </div>
      </div>
    </section>
    <section class="stamppop_info_wrapper">
      <div class="stamppop_info">
        <div class="title_box">
          <h1>비대면 여행 필수 앱 - 스탬프 팝</h1>
          <h1>기술로 더 즐거운 여행을 만듭니다<span>.</span></h1>
        </div>
        <carousel
          :items="1"
          :loop="true"
          :autoplay="true"
          :autoplayTimeout="10000"
          :autoplayHoverPause="true"
          :mouseDrag="true"
          :touchDrag="true"
          :navText="['']"
        >
          <div v-for="(item, index) in slide01" :key="index" class="tab">
            <div class="img_box">
              <img
                :class="{
                  fade: isFade,
                }"
                :key="item.src"
                v-show="isFade == true"
                :src="require(`@/assets/img/${item.src}`)"
                :alt="item.alt"
              />
            </div>
            <div class="description">
              <span>{{ item.span }}</span>
              <h3 v-html="item.h3"></h3>
              <p v-html="item.p"></p>
            </div>
          </div>
        </carousel>
      </div>
    </section>

    <section class="stamppop_setting_wrapper">
      <div class="stamppop_setting">
        <div class="img_box">
          <img src="@/assets/img/layer-5.png" alt="열기구" />
          <img src="@/assets/img/cloud-2.png" alt="구름" />
        </div>

        <div class="title_box">
          <h1>제약 없는 스탬프 설정!</h1>
          <h1>스탬프 팝 이렇게 활용해보세요<span>.</span></h1>
        </div>
        <carousel
          :items="1"
          :loop="true"
          :autoplay="true"
          :autoplayTimeout="6000"
          :autoplayHoverPause="true"
          :mouseDrag="true"
          :touchDrag="true"
          :navText="['']"
        >
          <div v-for="(item, index) in slide02" :key="index" class="tab">
            <img :src="require(`@/assets/img/${item.src}`)" :alt="item.alt" />
            <h3 v-html="item.h3"></h3>
            <p v-html="item.p"></p>
          </div>
        </carousel>
      </div>
    </section>
    <section class="plan_wrapper">
      <div class="plan">
        <div class="title_box">
          <h1>스탬프 팝 페스티벌 요금제 출시!</h1>
          <h1>원하는 기간만큼만 저렴하게 도입하세요<span>.</span></h1>
          <h3>스탬프 팝 페스티벌 요금제</h3>
          <p>
            축제, 행사 등 원하는 기간만큼만 결제하여 스탬프 팝 플랫폼을 이용하는
            단기 기간용 요금제입니다.
          </p>
        </div>
        <div class="card_box">
          <div class="card_row">
            <div
              class="card"
              v-for="(item, index) in product.items"
              :key="index"
            >
              <span class="discount" v-show="item.discountRate != 0"
                >할인적용!</span
              >
              <div class="img_box">
                <div class="svg_container">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 88 88">
                    <path
                      fill-rule="evenodd"
                      d="M35.741 1.411l36.705 9.835c10.67 2.859 17.001 13.826 14.143 24.495l-9.835 36.705c-2.859 10.67-13.826 17.001-24.495 14.143l-36.706-9.836C4.884 73.894-1.448 62.928 1.411 52.258l9.835-36.705C14.105 4.884 25.072-1.448 35.741 1.411z"
                      opacity=".2"
                    />
                    <path
                      fill-rule="evenodd"
                      d="M27 7h34c11.046 0 20 8.954 20 20v34c0 11.046-8.954 20-20 20H27C15.954 81 7 72.046 7 61V27C7 15.954 15.954 7 27 7z"
                    />
                  </svg>
                </div>
                <span v-if="item.amount != 0">{{ item.name }}</span>
                <span v-else class="separate_name">{{ item.name }}</span>
              </div>
              <p v-if="item.amount != 0">{{ item.description }}</p>
              <p v-else class="separate_desc" v-html="item.description"></p>
              <div class="price">
                <div class="price">
                  <span v-if="item.discountRate != 0" class="before_dc">
                    <span></span>
                    {{ $n(item.originPrice) }}만원
                  </span>
                  <h1 v-if="item.amount != 0">
                    {{ $n(item.amount) }}<span>만원</span>
                  </h1>
                  <h1 v-else class="separate_inquery">별도문의</h1>
                </div>
              </div>
              <p v-if="item.amount != 0">VAT별도</p>
            </div>
          </div>
          <div class="price_notice">
            <p>
              * 운영기간 1개월 이상 또는 스탬프 수 21개 이상으로 운영하고자 하는
              경우는 별도 문의 부탁드립니다.
            </p>
            <p>
              - 우측 하단 채팅상담 버튼을 눌러 문의사항을 남겨 주시면 빠르게
              답변받으실 수 있습니다.
            </p>
            <p>- 전화 : 02-6337-0310</p>
            <p>- 메일 : festival@nextinnovation.kr</p>
          </div>
          <div class="btn_container">
            <base-button
              type="primary"
              @click="$router.push({ name: 'EntryCost' })"
              >자유롭게 요금 계산하기</base-button
            >
          </div>
        </div>
        <div class="services_container">
          <h3>제공 서비스 안내</h3>
          <div class="services services_01">
            <div class="service">
              <img
                src="@/assets/img/enrollment-white.png"
                alt="축제 정보 등록"
              />
              <h4>축제 정보 등록</h4>
              <p>
                스탬프 팝 앱 내<br />
                축제/행사 정보 노출
              </p>
            </div>
            <div class="service">
              <img
                src="@/assets/img/flatform-white.png"
                alt="간편한 플랫폼 임대"
              />
              <h4>간편한 플랫폼 임대</h4>
              <p>
                별도의 앱을 제작하지 않고<br />
                스탬프투어 운영 가능
              </p>
            </div>
            <div class="service">
              <img src="@/assets/img/stamp-white.png" alt="스탬프 10개 등록" />
              <h4>스탬프/미션 리뷰 수집</h4>
              <p>
                리뷰 기능이 있어 방문객<br />
                반응을 쉽게 확인 가능
              </p>
            </div>
            <div class="service">
              <img
                src="@/assets/img/design-white.png"
                alt="스탬프 디자인 제작"
              />
              <h4>필요한 만큼 스탬프 등록</h4>
              <p>
                관광지(축제) 내 <br />획득할 스탬프를<br />
                필요한 수 만큼 등록 가능
              </p>
            </div>
          </div>
          <div class="services services_02">
            <div class="service">
              <img
                src="@/assets/img/gps-white.png"
                alt="다양한 스탬프 획득 방식"
              />
              <h4>다양한 스탬프 획득 방식</h4>
              <p>
                GPS와 QR코드로<br />
                스탬프 획득 가능
              </p>
            </div>
            <div class="service">
              <img
                src="@/assets/img/mission-white.png"
                alt="미션 및 리워드 제공 기능"
              />
              <h4>미션 및 리워드 제공 기능</h4>
              <p>
                미션 2개까지 설정 및<br />
                리워드 제공방식 선택 가능<br />
                (현장지급/우편수령)
              </p>
            </div>
            <div class="service">
              <img
                src="@/assets/img/statistics-white.png"
                alt="이용 통계 제공"
              />
              <h4>이용 통계 제공</h4>
              <p>
                월 별 스탬프 별,<br />
                리워드 수령 수 통계 제공
              </p>
            </div>
            <div class="service">
              <img src="@/assets/img/promotion-white.png" alt="블로그 홍보" />
              <h4>블로그 홍보</h4>
              <p>
                스탬프 팝 블로그 내<br />
                무료 홍보 진행
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="email_question">
        <p>
          스탬프 팝 플랫폼을 장기로 이용하고자 하시는 경우
          festival@nextinnovation.kr로 문의바랍니다.
        </p>
      </div>
    </section>
    <section class="application_wrapper" ref="refApplication">
      <div class="application">
        <div class="title_box">
          <h1>스탬프 팝 페스티벌</h1>
          <h1>어떻게 입점하나요<span>?</span></h1>
        </div>
        <div class="sequence_box">
          <div class="sequence_title">
            <img src="@/assets/img/rectangle-48.png" alt="입점순서" />
            <h4>입점순서</h4>
          </div>
          <div class="sequence_contents">
            <div class="sequence_content">
              <span>01</span>
              <p>
                회원가입<br />
                이용권결제
              </p>
            </div>
            <div class="sequence_content">
              <span>02</span>
              <p>
                축제, 스탬프,<br />
                미션 정보입력
              </p>
            </div>
            <div class="sequence_content">
              <span>03</span>
              <p>
                스탬프 팝<br />
                담당자 확인
              </p>
            </div>
            <div class="sequence_content">
              <span>04</span>
              <p>
                스탬프 팝<br />
                플랫폼 셋팅
              </p>
            </div>
            <div class="sequence_content">
              <span>05</span>
              <p>
                축제 일정에<br />
                맞추어 오픈
              </p>
            </div>
          </div>
          <div class="disturbance_box">
            <img src="@/assets/img/rectangle-46.png" alt="약 1~3일 소요" />
            <p>약 1~3일 소요</p>
          </div>
        </div>
        <div class="notice_box">
          <h3>
            <span> </span>
            확인해주세요.
          </h3>
          <p>
            - 스탬프 팝 플랫폼은 정보입력 후 세팅 완료까지 약 1~3일이
            소요됩니다.<br />
            축제 시작 전 여유를 두고 신청해주세요. 빠른 진행을 원하시는 경우
            고객센터로 문의해주세요.
          </p>
          <p>
            - 업로드한 축제 자료는 스탬프 팝 담당자가 확인한 후 플랫폼 셋팅을
            시작합니다.<br />
            자료 보완이 필요한 경우 회원정보의 연락처로 연락을 드립니다.
          </p>
        </div>
      </div>
    </section>
    <section class="home_inquiry_wrapper">
      <div class="inquiry">
        <h1>스탬프 팝 입점 문의</h1>
        <h1>
          <a href="tel:02-6337-0310">02-6337-0310</a>이나
          <span class="email" @click="sendEmail"
            >festival@nextinnovation.kr</span
          >으로<br />
          연락주시면 친절히 상담해드리겠습니다<span>.</span>
        </h1>
      </div>
    </section>
    <section class="appdown_wrapper">
      <div class="link_box">
        <div class="link_box_bg">
          <div class="left"></div>
          <div class="right"></div>
        </div>
        <div class="links_container">
          <div class="download">
            <h3>스탬프 팝 앱 다운받기</h3>
            <div class="app_icon">
              <img src="@/assets/img/app-icon.png" alt="스탬프 팝 앱" />
            </div>
            <div class="links">
              <a
                href="https://apps.apple.com/kr/app/스탬프팝-스탬프투어로-떠나는-국내여행/id1207835232"
                target="_blank"
                class="link"
              >
                <p>앱스토어 다운로드</p>
                <img src="@/assets/img/apple.png" alt="앱스토어 다운로드" />
              </a>
              <a
                href="https://play.google.com/store/apps/details?id=kr.nextinnovation.stamppop"
                target="_blank"
                class="link"
              >
                <p>구글스토어 다운로드</p>
                <img src="@/assets/img/google.png" alt="구글스토어 다운로드" />
              </a>
            </div>
          </div>
          <div class="question">
            <h3>스탬프 팝 입점 문의하기</h3>
            <div class="chat" @click.prevent.stop="onChannelIO">
              <h4>채팅 문의하기</h4>
              <p>클릭하시면 채팅문의로 바로 연결됩니다.</p>
              <img src="@/assets/img/chatbot.png" alt="채팅 문의하기" />
            </div>
            <div class="email" @click="sendEmail">
              <h4>이메일 문의하기</h4>
              <p>festival@nextinnovation.kr</p>
              <img src="@/assets/img/mail@2x.png" alt="이메일 문의하기" />
            </div>
          </div>
        </div>
      </div>
    </section>
    <button
      type="button"
      @click="orderProduct"
      class="mobile_application"
      v-if="isApply"
    >
      <p>페스티벌 신청하기</p>
      <span
        ><img src="@/assets/img/rectangle-43.png" alt="페스티벌 신청하기"
      /></span>
    </button>
    <div class="top_btn" :class="{ active: isBtnActive }" @click="toTop">
      <img src="@/assets/img/rectangle-top.png" alt="제일 위로" />
    </div>
  </main>
</template>

<style lang="scss" scoped>
@import "./index.scss";
</style>

<script>
import { mapGetters } from "vuex";
import Dialog from "@/components/Dialog.vue";
import carousel from "vue-owl-carousel2";
import client from "api-client";
export default {
  name: "Home",
  components: {
    carousel,
  },
  data() {
    return {
      product: {
        items: [],
        totalItems: 0,
      },
      active02: "0",
      activeEntry: "0",
      pagination: [
        "GPS/QR코드 인증",
        "관광지(축제)홍보",
        "스탬프",
        "미션",
        "선물신청",
        "여행",
        "축제",
        "기업/학교",
        "박람회",
        "장터/상권",
      ],
      slide01: [
        {
          span: "GPS / QR 코드 인증",
          h3: "종이와 도장없이<br />GPS와 QR코드로 방문인증!<br />비대면 시대, 더 안전해요",
          p: "스탬프 팝 앱은 스탬프가 설정된 위치에서 방문객의 핸드폰 위치를<br />인식하여 스탬프를 부여합니다. 건물 내에 위치한 장소이거나 스탬프 간의<br />거리가 좁은 경우에는 QR코드로 인증할 수 있습니다.<br />안전한 축제에는 스탬프 팝이 필수죠!",
          src: "mobile-01.png",
          alt: "GPS / QR 코드 인증",
        },
        {
          span: "관광지(축제)홍보",
          h3: "우리 지역 관광지(축제)를<br />스탬프팝 앱에 홍보하세요",
          p: "Since 2015, 수많은 여행 매니아들이 사용하는 스탬프팝<br />앱에 우리 지역 축제를 홍보하세요.  축제 시작 전부터<br />스탬프 팝 앱 내에 노출이 가능합니다.",
          src: "mobile-02.png",
          alt: "GPS / QR 코드 인증",
        },
        {
          span: "스탬프",
          h3: "꼭 방문해야 할 관광지(축제장소)를<br />스탬프로 알려주세요",
          p: "축제 여기저기 방문객이 꼭 들려야 할 장소, 행사를 스탬프로<br />등록해 더 많은 축제 이용을 유도할 수 있습니다.<br />1개 축제 당 스탬프는 10개까지 등록 가능합니다.",
          src: "mobile-03.png",
          alt: "GPS / QR 코드 인증",
        },
        {
          span: "미션",
          h3: "방문객 선물이벤트를 기획하셨나요?<br />미션을 설정해 이벤트 참여율을<br />높여보세요",
          p: "스탬프를 획득해 리워드(선물)을 신청할 수 있는 미션 기능을 이용하세요.<br />별도 이벤트용 리플렛을 제작할 필요없이<br />스탬프 팝 앱으로 선물이벤트를 진행할 수 있습니다.",
          src: "mobile-04.png",
          alt: "GPS / QR 코드 인증",
        },
        {
          span: "선물신청",
          h3: "미션 선물 관리?<br />신청도, 지급도 앱으로 간편하게",
          p: "스탬프 팝 앱에서는 선물신청도 간편합니다.<br />유저가 직접 신청한 선물 지급 정보가 담당자에게 전달이 되니까,<br />선물 신청서를 따로 받을 필요없이 스탬프 팝 앱 하나로 해결하세요!",
          src: "mobile-05.png",
          alt: "GPS / QR 코드 인증",
        },
      ],
      slide02: [
        {
          h3: "#여행 #종합관광안내",
          p: "우리 지역의 아름다운 관광지를 알려주세요.<br />스탬프와 미션을 통해 구석구석 즐겨준 <br class='mobile_br' />방문객에게 추억을 선물하세요.<br />GPS와 QR코드 스탬프로 <br class='mobile_br' />관리담당자가 없어도 방문 인증 가능!",
          src: "setting-1.png",
          alt: "종합관광안내",
        },
        {
          h3: "#축제 #참여유도",
          p: "우리 축제에서 꼭 방문해야 할 <br class='mobile_br'/>핵심 공간을 소개하세요.<br />체험프로그램의 참여율을 높이고, <br class='mobile_br'/>더 오랜 시간 축제를 즐기게 하세요.<br /> 방문객이 체험프로그램을 마친 후 <br class='mobile_br' />스탬프를 획득하도록 하려면? <br class='mobile_br' />QR코드 스탬프로 가능해요!",
          src: "setting-2.png",
          alt: "참여유도",
        },
        {
          h3: "#기업워크숍 #학교수련회 <br class='mobile_br' />#활동프로그램",
          p: "공간을 이동하면서 진행하는 <br class='mobile_br' />활동 프로그램을 기획하셨나요?<br /> 스탬프와 미션을 설정하면 <br class='mobile_br' />프로그램 진행이 한결 수월해집니다.<br /> QR코드 스탬프를 이용하면 <br class='mobile_br' />한정된 공간에서도 활용 OK!",
          src: "setting-3.png",
          alt: "활동프로그램",
        },
        {
          h3: "#박람회 #전시장 #방문유도",
          p: "부스가 많아 복잡한 박람회장에서는 <br class='mobile_br' />같은 자리만 뱅뱅 돌다 퇴장할 수도 있어요.<br />주요 거점이 되는 장소에 <br class='mobile_br' />QR코드 스탬프를 설치하고 <br />방문객이 모든 공간을<br class='mobile_br' />둘러볼 수 있도록 유도하세요.",
          src: "setting-4.png",
          alt: "방문유도",
        },
        {
          h3: "#장터이벤트 #소규모상권 <br class='mobile_br' />#상권활성화",
          p: "여기저기 많이 방문할 수 있도록 <br class='mobile_br' />스탬프를 설치하고,<br />미션 달성 시 재방문을 유도할 수 있는 <br class='mobile_br' />선물을 증정해보세요.<br />자연스럽게 따라오는 매출신장 효과!",
          src: "setting-5.png",
          alt: "상권활성화",
        },
      ],
      entrySlide: [
        {
          src: "entry-1.png",
          alt: "더 많이 알리고",
          h3: "더 많이 알리고",
          p: "우리 관광지(축제)를 전국의 <br />스탬프 팝 유저에게 홍보하세요. <br />프로그램도함께 안내할 수 있어요.",
        },
        {
          src: "entry-2.png",
          alt: "자원은 줄이고",
          h3: "자원은 줄이고",
          p: "GPS와 QR코드로 방문객이 직접 스탬프 획득!<br />스탬프 팝을 이용하면, 스탬프 리플렛과<br />도장, 현장 요원이 필요 없습니다.",
        },
        {
          src: "entry-3.png",
          alt: "제대로 즐길 수 있도록",
          h3: "제대로 즐길 수 있도록",
          p: "축제를 얼마나 잘 즐기는지가 성공한 축제의 조건!<br />스탬프 팝이 축제 프로그램을 알리고 구석구석<br />찾아가게 도와드리겠습니다.",
        },
      ],
      entrySlidePagination: ["", "", ""],
      visibleEntry: 0,
      partners: [
        { src: "부여군.png", alt: "부여군" },
        { src: "완주군.png", alt: "완주군" },
        { src: "정읍.png", alt: "정읍" },
        { src: "곡성.png", alt: "곡성" },
        { src: "양주.png", alt: "양주" },
        { src: "장성.png", alt: "장성" },
        { src: "김제시.png", alt: "김제시" },
        { src: "코레일.png", alt: "코레일" },
        { src: "전라북도.png", alt: "전라북도" },
        { src: "의정부.png", alt: "의정부" },
        { src: "부산시민공원.png", alt: "부산시민공원" },
        { src: "속초시.png", alt: "속초시" },
        { src: "고성.png", alt: "고성" },
        { src: "양양.png", alt: "양양" },
        { src: "인제.png", alt: "인제" },
      ],
      windowTop: "",
      sectionsTop: [],
      interval03: null,
      entryInterval: null,
      window: {
        width: 0,
      },
      isFade: true,
      isBtnActive: false,
      isApply: false,
    };
  },
  computed: {
    ...mapGetters(["isAuthenticated"]),
    entryLen() {
      return this.entrySlide.length;
    },
  },
  created() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  mounted() {
    this.customPagination();
    window.addEventListener("scroll", this.onScroll);
    this.interval03 = setInterval(() => {
      if (this.slide01[0].src == "mobile-01.png") {
        this.slide01[0].src = "mobile-01_2.png";
      } else {
        this.slide01[0].src = "mobile-01.png";
      }
      if (this.slide01[1].src == "mobile-02.png") {
        this.slide01[1].src = "mobile-02_2.png";
      } else {
        this.slide01[1].src = "mobile-02.png";
      }
      if (this.slide01[2].src == "mobile-03.png") {
        this.slide01[2].src = "mobile-03_2.png";
      } else {
        this.slide01[2].src = "mobile-03.png";
      }
      if (this.slide01[3].src == "mobile-04.png") {
        this.slide01[3].src = "mobile-04_2.png";
      } else {
        this.slide01[3].src = "mobile-04.png";
      }
      if (this.slide01[4].src == "mobile-05.png") {
        this.slide01[4].src = "mobile-05_2.png";
      } else {
        this.slide01[4].src = "mobile-05.png";
      }
    }, 3000);
    this.fetch();
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.onScroll);
    clearInterval(this.interval03);
  },
  methods: {
    async fetch() {
      let params = {
        sort: "amount.asc",
        size: 30,
      };
      await client.productFindAll(params).then(
        (response) => {
          const { data } = response;
          let zeroAmountItem = [];
          let items = [];
          data.items.forEach((item) => {
            item.amount = item.amount / 10000;
            item.originPrice = item.originPrice / 10000;
            if (item.amount != 0) {
              items.push(item);
            } else if (item.amount == 0 && item.operatingDay != 0) {
              zeroAmountItem.push(item);
            }
          });
          this.product.items = items.concat(zeroAmountItem);
          this.totalItems = data.totalItems;
        },
        (error) => {
          const { data } = error.response;
          let message = data.message;
          this.$modal.show(
            Dialog,
            {
              title: "알림",
              content: message,
            },
            {
              adaptive: true,
              width: "90%",
              maxWidth: 600,
              height: "auto",
            },
            {
              "before-open": () => {},
              "before-close": () => {},
            }
          );
        }
      );
    },
    onChannelIO() {
      document.getElementById("show_chatbot").click();
    },
    customPagination() {
      const dots = document.querySelectorAll(".owl-dot span");
      this.pagination.forEach((text, i) => {
        dots[i].innerHTML = text;
      });
    },
    orderProduct() {
      if (this.isAuthenticated) {
        this.$router.push({ name: "FestivalOrderProduct" });
      } else {
        this.$modal.show(
          Dialog,
          {
            type: "confirm2",
            title: "알림",
            content:
              "스탬프 팝 입점 신청을 위해 로그인해주세요.<br /> 아직 회원이 아닌 경우 회원가입을 해주세요.",
            trueText: "회원가입하기",
            falseText: "로그인하기",
          },
          {
            adaptive: true,
            width: "90%",
            maxWidth: 600,
            height: "auto",
          },
          {
            "before-open": () => {},
            "before-close": (response) => {
              if (response.params.confirm) {
                this.$router.push({ name: "Register" });
              } else {
                this.$router.push({ name: "Login" });
              }
            },
          }
        );
      }
    },
    sendEmail() {
      window.open("mailto:festival@nextinnovation.kr");
    },
    toTop() {
      window.scrollTo(0, 0);
    },
    onScroll() {
      this.windowTop = window.top.scrollY;
      if (this.windowTop >= 2000) {
        this.isBtnActive = true;
      } else {
        this.isBtnActive = false;
      }

      let visualHeight = this.$refs.refVisual.offsetHeight;

      if (this.windowTop >= visualHeight / 2) {
        this.isApply = true;
      } else {
        this.isApply = false;
      }
    },
    handleResize() {
      this.window.width = window.innerWidth;
    },
    clear03() {
      clearInterval(this.interval03);
    },
  },
};
</script>
